import s from './parallel_items.module.scss'

const ParallelItems = ({ left, right }) => (
   <div className={ s.parallel_items }>
      <div className={ s.left_item }>
         {left}
      </div>
      <div className={ s.right_item }>
         {right}
      </div>
   </div>
)

export default ParallelItems